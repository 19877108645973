import React from "react"
import { graphql } from "gatsby"

export default function Component(props) {
    console.log("episodes", props)
  return props.params.title +  props.data.directusPodcast.episodes[0].title// highlight-line
}

// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.

export const query = graphql`
  query ($id: String) {
    directusPodcast(id: { eq: $id }) {
      title
      subtitle
      url
      episodes {
        title
        subtitle
      }
    }
  }
`